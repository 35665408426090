import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import type { User } from 'oidc-client-ts';
import { ErrorBoundary } from './components/ErrorBoundary';
import { InvalidLogin } from './components/InvalidLogin';
import { config } from './config';
import { main } from './configuration';
import { handleLoginRedirect } from './configuration/login/redirect';
import { store } from './configuration/setup/store';
import { router } from './routes/Router';

const renderApplication = ({ user }: { user: User | null }) => {
    const container = document.getElementById('root');
    const root = createRoot(container as HTMLElement);

    let showApp = true;
    try {
        showApp = (user as User).profile.sub.startsWith('azure-my-rio-de:');
    } catch (e) {
        console.error(e);
    }

    if (showApp) {
        root.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
            </ErrorBoundary>
        );
    } else {
        root.render(<InvalidLogin idToken={user?.id_token} />);
    }
};

const isDev = import.meta.env.DEV;
const isProd = import.meta.env.PROD;
const isProdPreview = import.meta.env.VITE_PRODUCTION_PREVIEW;

if ((isDev && config.enableMockServer) || isProdPreview) {
    import('../mocks/browser').then(({ startWorker }) => startWorker()).then(() => main(renderApplication));
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (isProd && !isProdPreview) {
    void main(renderApplication);
}
