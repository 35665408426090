import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { config } from '../../config';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getIdToken } from '../../configuration/tokenHandling/tokenSlice';
import { DEFAULT_ROUTE } from '../../routes/Router';

const AppHeader = () => {
    const idToken = useAppSelector(getIdToken);
    const postLogoutRedirectUri = config.login.postLogoutRedirectUri;

    const navItems = [
        {
            key: 'rental',
            route: (
                <NavLink to={DEFAULT_ROUTE}>
                    <FormattedMessage id={'intl-msg:navigation-bar.sublink.rental'} />
                </NavLink>
            ),
        },
    ];

    const environment = import.meta.env.PROD ? 'production' : 'local';

    const userMenuItem = (
        <DefaultUserMenu
            environment={environment}
            postLogout={
                idToken && postLogoutRedirectUri
                    ? { redirectUri: postLogoutRedirectUri, idTokenHint: idToken }
                    : undefined
            }
            disableCookieModal
        />
    );

    return (
        <ApplicationHeader
            label={<FormattedMessage id='intl-msg:starterTemplate.moduleName' />}
            navItems={navItems}
            actionBarItems={[userMenuItem]}
        />
    );
};

export default AppHeader;
